/* jshint esversion: 6 */
/*
module "Filter.js"
_FilterSelector class/id/el of Filter.

*/

class Filter {
	constructor(_filterSelector) {
		this.filterSelector = document.querySelector(_filterSelector);


		if (this.filterSelector) {
			this.InitFilter(this.filterSelector);
		}
	}

	InitFilter(selector) {
		let filters = selector.querySelectorAll('li');

		Array.from(filters).forEach(function (filter) {
			filter.addEventListener('click', function(e){
				e.preventDefault();
				


				let thisID = this.getAttribute('id');
				let thisMatch = document.querySelector('div' + thisID);

				// window.scroll(0, document.querySelector(selector).offsetTop - offset);
				window.scroll({
					top: thisMatch.offsetTop,
					behavior: 'smooth',
				})
				
				
				
				// this.classList.toggle('active');
				// if(this.classList.contains('active')){
				// 	thisMatch.classList.add('hidden');
				// } else {
				// 	thisMatch.classList.remove('hidden');
				// }
			});
		});
	}

}

export default Filter;
