/* jshint esversion: 6 */
/*
module "Media.js"
_MediaSelector class/id/el of media.

*/

class Media {
	constructor(_mediaSelector) {
		this.mediaSelector = document.querySelectorAll(_mediaSelector);


		if (this.mediaSelector) {
			this.InitMedia(this.mediaSelector);
		} else {
			console.log("no media here");
		}
	}

	InitMedia(selector) {
		const self = this;

		Array.from(selector).forEach(function (element) {
			
		});


		if(document.querySelectorAll('.youtube')){
			this.initYoutubeVideos();
		}
		if(document.querySelectorAll('video')){
			this.initHTMLVideo();
		}

	}

	initHTMLVideo(selector){
		let videos = document.querySelectorAll('video');
		Array.from(videos).forEach(function(videoEl) {
			let controls = videoEl.parentNode.querySelector('.js-controls');

			controls.addEventListener('click', function(e){
				controls.classList.add('hidden');
				videoEl.play();
				videoEl.setAttribute('controls', true);
			});
		});
	}

	initYoutubeVideos(selector) {
		var videos = document.getElementsByClassName("youtube");

		for (var i = 0; i < videos.length; i++) {
			
			var youtube = videos[i];
			var id = youtube.getAttribute('id');
			
		

			if(id.includes('youtu') || id.includes('youtube')){
				if (id.indexOf("=") != -1) {
					let justID = id.split('=')[1];
					youtube.setAttribute('id', justID);
				} else if (id.indexOf("/") != -1) {
					let shortID = id.split("/")[3];
					youtube.setAttribute('id', shortID);
				}
				// Based on the YouTube ID, we can easily find the thumbnail image
				var img = document.createElement("img");
				img.setAttribute("src", "https://i.ytimg.com/vi/" + youtube.id + "/hqdefault.jpg");
				img.setAttribute("class", "thumb");

				// Overlay the Play icon to make it look like a video player
				// var circle = document.createElement("div");
				// circle.setAttribute("class","circle");
				youtube.appendChild(img);
				// youtube.appendChild(circle);
				let controls = youtube.parentNode.querySelector('.js-controls');
				// Attach an onclick event to the YouTube Thumbnail
				controls.onclick = function () {
					let player = controls.parentNode.querySelector('.youtube');
					// Create an iFrame with autoplay set to true
					var iframe = document.createElement("iframe");
					iframe.setAttribute("src",
						"https://www.youtube.com/embed/" + player.id
						+ "?autoplay=1&autohide=1&border=0&wmode=opaque&enablejsapi=1");

					// The height and width of the iFrame should be the same as parent
					iframe.style.width = player.style.width;
					iframe.style.height = player.style.height;

					// Replace the YouTube thumbnail with YouTube HTML5 Player
					player.parentNode.replaceChild(iframe, this);
				};
			} else {
			
				let controls = youtube.parentNode.querySelector('.js-controls');

				controls.onclick = function () {
					let thisMedia = this.parentNode.querySelector('.youtube');
		
					window.open(thisMedia.id, "_blank");
				};
			}
		}
	}

	
}

export default Media;

// export const mount = (thisEl) => {
// 	const mediaInstance = new Media(thisEl);
// }