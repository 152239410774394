/*jshint esversion: 6 */
// import core dependencies
import _ from 'lodash';

import arrive from 'arrive';

// import scss for compilation
// import '../scss/main.scss';

import '../scss/main.scss';

// import javascript in bundle
import Media from './components/Media.js';
import Newsletter from './components/Newsletter.js';
import Menu from './components/Menu.js';
import Slider from './components/Slider.js';
import Filter from './components/Filter.js';
import Parallax from './components/Parallax.js';
import CookieNotice from './components/Cookie.js';
function App() {
	const mainMenu = new Menu('.main-header', '.nav-button');
	const media = new Media('.media');
	const newsletter = new Newsletter('.newsletter-form');
	const slider = new Slider('.discography-overview');
	const sliderGallery = new Slider(
		'.image-gallery .gallery-inner .swiper-container',
	);
	const filter = new Filter('.filter');
	const parallax = new Parallax('.discography-overview');
	const cookie = new CookieNotice('.cookie-notice');
}

document.addEventListener('DOMContentLoaded', function () {
	if (window.STORYBOOK_ENV) {
		let root = document.getElementById('root');

		root.arrive('.main-header', function () {
			const mainMenu = new Menu('.main-header', '.nav-button');
		});
		root.arrive('.media', function () {
			const media = new Media('.media');
		});
	} else {
		App();
	}
});
