/* jshint esversion: 6 */
/*
module "Slider.js"
_sliderSelector class/id/el of slider.

*/

// import Swiper JS
import Swiper, { Pagination, Navigation, EffectFade, Autoplay } from 'swiper';
// import Swiper styles
Swiper.use([Pagination, Navigation, EffectFade, Autoplay]);


class Slider {
	constructor(_sliderSelector) {
		this.sliderSelector = _sliderSelector;
		const selector = this.sliderSelector;

		if (_sliderSelector === ".discography-overview" && Swiper) {
			this.InitDiscographySlider(this.sliderSelector);
		} else if (_sliderSelector === ".image-gallery .gallery-inner .swiper-container" && Swiper){
			this.InitGallerySlider(this.sliderSelector);
			
		} else {
			this.InitDefaultSlider(this.sliderSelector);
		}
	}


	InitDiscographySlider(selector) {
		let sliderSelectors = document.querySelectorAll(selector);
		var sliders = [];
		var flag = false;
		var breakpoint = window.matchMedia('(min-width:1024px)');
	
		if (breakpoint.matches === true && flag === false) {
			flag = true;
		}

		window.addEventListener('resize', function(e){
			breakpoint = window.matchMedia('(min-width:1024px)');
			
			if (breakpoint.matches === true){
				flag = true;
			} else {
				flag = false;
			}
		
			if(sliders.length !== 0 && flag === true){
				Array.from(sliderSelectors).forEach(function (slider) {
					slider.swiper.destroy(true, true);
					
				});
			sliders = [];
			} else if (sliders.length === 0 && flag === false) {
				Array.from(sliderSelectors).forEach(function (slider) {
					let thisSliderID = slider.getAttribute('data-slider');
					let thisSlider = new Swiper(selector, {
						loop: true,
						autoplay: false,
						spaceBetween: 18,
						centeredSlides: true,
						watchSlidesProgress: true,
						watchSlidesVisibility: true,
					});
					sliders.push(thisSlider);
				});
			} else {
			}
		});

		if(breakpoint.matches === true) {
			if (sliders.length !== 0) {
				Array.from(sliderSelectors).forEach(function (slider) {
					slider.swiper.destroy(true, true);		
				});
			}	
		} else {
			Array.from(sliderSelectors).forEach(function (slider) {
				let thisSliderID = slider.getAttribute('data-slider');
				let thisSlider = new Swiper(selector, {
					loop: true,
					autoplay: false,
					spaceBetween: 18,
					centeredSlides: true,
					watchSlidesProgress: true,
					watchSlidesVisibility: true,
				});
				sliders.push(thisSlider);
			});
		}
	}

	InitGallerySlider(selector){


		let sliderSelectors = document.querySelectorAll(selector);
		var sliders = [];
		var flag = false;
		var breakpoint = window.matchMedia('(min-width:1024px)');

		if (breakpoint.matches === true && flag === false) {
			flag = true;
		}

		window.addEventListener('resize', function (e) {
			breakpoint = window.matchMedia('(min-width:1024px)');
			
			if (breakpoint.matches === true) {
				flag = true;
			} else {
				flag = false;
			}
			if (sliders.length !== 0 && flag === true) {
				
				Array.from(sliderSelectors).forEach(function (slider) {

					slider.swiper.destroy(true, true);
			
				});
				sliders = [];

			} else if (sliders.length === 0 && flag === false) {

				Array.from(sliderSelectors).forEach(function (slider) {
					// let thisSliderID = slider.getAttribute('data-slider');
					let thisSlider = new Swiper(selector , {
						loop: true,
						autoplay: false,
						spaceBetween: 18,
						centeredSlides: true,
						watchSlidesProgress: true,
						watchSlidesVisibility: true,
					});
					sliders.push(thisSlider);
				});
			} else {

			}
		});

		if (breakpoint.matches === true) {
			if (sliders.length !== 0) {
				Array.from(sliderSelectors).forEach(function (slider) {
					slider.swiper.destroy(true, true);
				});
			}
		} else {
			Array.from(sliderSelectors).forEach(function (slider) {
				// let thisSliderID = slider.getAttribute('data-slider');
				let thisSlider = new Swiper(selector, {
					loop: true,
					autoplay: false,
					spaceBetween: 18,
					centeredSlides: true,
					watchSlidesProgress: true,
					watchSlidesVisibility: true,
				});
				sliders.push(thisSlider);
			});
		}
	}

	InitDefaultSlider(selector) {
		let sliderSelectors = document.querySelectorAll(selector);
		var sliders = [];

		Array.from(sliderSelectors).forEach(function (slider) {
			// let thisSliderID = slider.getAttribute('data-slider');
			let thisSlider = new Swiper(selector, {
				loop: true,
				autoplay: false,
				spaceBetween: 18,
				centeredSlides: true,
				watchSlidesProgress: true,
				watchSlidesVisibility: true,
			});
			console.log(thisSlider);
			sliders.push(thisSlider);
		});
	}
}

export default Slider;


