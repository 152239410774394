/* jshint esversion: 6 */
/*
module "Menu.js"
_navigationSelector class/id/el of navigation.
_hamburgerSelector class of hamburger or false.
*/
import Hammer from 'hammerjs';

class Menu {
	constructor(_navigationSelector, _hamburgerSelector) {

		this.hamburgerSelector = document.querySelectorAll(_hamburgerSelector);
		this.navigationSelector = document.querySelectorAll(_navigationSelector);

		if (this.navigationSelector.length != 0) {

			this.Navigation(this.navigationSelector, false);
			this.navigationLinkHover(this.navigationSelector);
			if (this.hamburgerSelector) {
				this.Hamburger(this.hamburgerSelector);
			}
		}
	}

	Navigation(selector, state) {
		let navigation = selector[0];
		let body = document.querySelector('body');

		if (state && navigation) {
			navigation.classList.add('open');
			body.classList.add('no-overflow');
		} else if (state === false && navigation.classList.contains('open')) {
			navigation.classList.remove('open');
			body.classList.remove('no-overflow');
		}
	}

	Hamburger(selector) {
		let hamburger = selector[0];

		hamburger.addEventListener('click', event => {
			hamburger.classList.toggle('open');
			this.toggleNavigation(hamburger.classList.contains('open'));
		});
	}

	toggleNavigation(mode) {
		this.Navigation(this.navigationSelector, mode); { }
	}

	navigationLinkHover(selector) {
		const header = selector[0];
		const nav = header.querySelectorAll('nav > ul')[0];
		let body = document.querySelector('body');
		// toggle mobile flag for mouseover handling
		let mobile;
		if (window.innerWidth < 1170) {
			mobile = true;
		} else {
			mobile = false;
		}


		// window.addEventListener('resize', function () {
		// 	if (window.innerWidth < 1280) {
		// 		mobile = true;
		// 	} else {
		// 		mobile = false;
		// 		body.classList.remove('no-overflow');
		// 	}
		// }, true)

	}


}

export default Menu;

