/* jshint esversion: 6 */
/*
module "Parallax.js"
_Selector class/id/el of Parallax.

*/
import Rellax from 'rellax';
import Scrollbar from 'smooth-scrollbar';

class Parallax {
	constructor(_Selector) {
		this.Selector = document.querySelector(_Selector);
		
		if (this.Selector) {
			this.InitParallax(this.Selector);
		}
	}

	

	InitParallax(selector) {
		var flag = false;
		var numArr = [2, 3, 4, 5, 3];

		// const scrollBar = Scrollbar.init(document.querySelector('body'), {
		// 	damping: 0.9,
		// });

		var breakpoint = window.matchMedia('(min-width:1024px)');
		const items = selector.querySelectorAll(".discography-link");

		if (breakpoint.matches === true) {
			flag = true;
			let i = 0;
			Array.from(items).forEach(function (item) {
				if(i >= numArr.length - 1){
					i = 0;
				} else {
					i++;
				}
				let rellax = new Rellax(item, {
					speed: numArr[i]
				});
			});
		}

		window.addEventListener('resize', function (e) {
			breakpoint = window.matchMedia('(min-width:1024px)');

			if (breakpoint.matches === true) {
				flag = true;
			} else {
				flag = false;
			}

			if(breakpoint === true){
				let i = 0;

				Array.from(items).forEach(function (item) {
					if (i >= numArr.length - 1) {
						i = 0;
					} else {
						i++;
					}
					let rellax = new Rellax(item, {
						speed: numArr[i]
					});
				});
			} else {
				Array.from(items).forEach(function (item) {
					item.rellax.destroy();
				});
				
			}
		});
	}



}

export default Parallax;

// export const mount = (thisEl) => {
// 	const FilterInstance = new Filter(thisEl);
// }
