/* jshint esversion: 6 */
/*
module "Newsletter.js"
_NewsletterSelector class/id/el of Newsletter.

*/

class Newsletter {
	constructor(_newsletterSelector) {
		this.newsletterSelector = document.querySelector(_newsletterSelector);


		if (this.newsletterSelector) {
			this.InitNewsletter(this.newsletterSelector);
		} else {
			console.log("no Newsletter here");
		}



	}
	InitNewsletter(selector){
		const form = document.querySelector('.newsletter-form');
		let fieldsets = selector.querySelectorAll('fieldset');
		let nextBtn = document.querySelector('.next-step');
		
		if(window.location.search === '?success'){
			setTimeout(function () { form.scrollIntoView('smooth'); }, 3000);	
			let activeField = document.querySelector('fieldset.active');
			activeField.classList.remove('active');
			let message = document.querySelector('.message');
			message.classList.add('active');
			
		}

		

		nextBtn.addEventListener('click', function(e){
			e.preventDefault();
			fieldsets[0].classList.remove('active');
			fieldsets[1].classList.add('active');
		});
	}

}

export default Newsletter;

// export const mount = (thisEl) => {
// 	const NewsletterInstance = new Newsletter(thisEl);
// }