/* jshint esversion: 6 */
/*
module "Cookie.js"
_CookieSelector class/id/el of Cookie.

*/

import Cookies from 'js-cookie';

class CookieNotice {
	constructor(_cookieSelector) {
		this.cookieSelector = document.querySelector(_cookieSelector);

		if (this.cookieSelector) {
			this.InitCookie(this.cookieSelector);
		}
	}

	InitCookie(selector) {
		if(!Cookies.get('cookie-notice')){
			selector.classList.remove('dismissed');
		}

		selector.addEventListener('click', function (e) {
			if (e.target.classList.contains('close-cookie') === true){
				e.preventDefault();
				console.log("close cookie");
				Cookies.set('cookie-notice', '1', { expires: 48 });
				selector.classList.add('dismissed');
			}
		});
	}

}

export default CookieNotice;
